import React from "react"
import { graphql, PageProps } from "gatsby"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import tagManager from "../utils/tag-manager"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

/** components */
import LayoutFloorPlan from "../components/LayoutFloorPlan"
import { theme, ColorPalette } from "../components/Theme"
import Gallery from "../components/Gallery"

/** dynamic sections */
import HeroTopCommunitySection from "../components/dynamic-sections/HeroTopCommunitySection"
import RibbonCommunitySection from "../components/dynamic-sections/RibbonCommunitySection"
import VideoCommunitySection from "../components/dynamic-sections/VideoCommunitySection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import GalleryImageSection from "../components/dynamic-sections/GalleryImageSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"

/** svg */
import GalleryIcon from "../../assets/gallery-icon.svg"
import VideoIcon from "../../assets/video-icon.svg"
import NorhartIcon from "../../assets/norhart-icon.svg"
import ApartmentIcon from "../../assets/apartment-icon.svg"
import MatterportIcon from "../../assets/matterport-icon.svg"
import StarIcon from "../../assets/star-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"
import useApartmentData from "../utils/useApartmentData"

/** color theme */
const colorPalette = theme.colorPalettes.blue

type FloorPlanProps = PageProps<{ floorPlan; allCommunityLeadSource }, { id: string }, unknown>

const FloorPlanTemplate: React.FC<FloorPlanProps> = (props) => {
  tagManager.pushOnce({
    event: "view-floorplan",
    units: [
      {
        regionId: props.data.floorPlan.community?.region?.id,
        communityId: props.data.floorPlan.community?.id,
        floorPlanId: props.data.floorPlan.id,
        floorPlanName: props.data.floorPlan.name,
        price: props.data.floorPlan.rentMax,
      },
    ],
  })

  let floorPlan = props.data.floorPlan

  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "",
          name: `${floorPlan.name} - ${floorPlan.community.name} Floor Plan | Norhart`,
          image: `${config.siteMetadata.siteUrl}/`,
        },
      },
    ],
  }

  const { seoTitle, seoDescription, seoKeywords, seriesLabel, phoneLabel, convertUrl } = useApartmentData(
    props.data.floorPlan.community.urlSlug
  )

  return (
    <LayoutFloorPlan
      title={`${floorPlan.name} - ${floorPlan.community.name} Floor Plan | Norhart`}
      description={`See the available Norhart ${floorPlan.name} floor plan for rent at ${floorPlan.community.name} in ${props.data.floorPlan.community.primaryProperty.city}, ${props.data.floorPlan.community.primaryProperty.state}.`}
      keywords={`${floorPlan.community.name}, ${props.data.floorPlan.community.primaryProperty.city}, ${floorPlan.name}, floor plan, norhart, luxury, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents`}
      convertUrl={convertUrl}
      imageTwitter={floorPlan.featuredImage?.url}
      imageOpenGraph={floorPlan.featuredImage?.url}
      colorPalette={colorPalette}
    >
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroTopCommunitySection image={floorPlan.featuredImage} />

      <RibbonCommunitySection
        title={`${floorPlan.name} Floor Plan`}
        tagLine={`${floorPlan.community.name}`}
        seriesLabel={seriesLabel}
        colorPalette={colorPalette}
      />

      <FloorPlanSection floorPlan={floorPlan} />

      {/* <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Apartments"
        title={`Experience the ${floorPlan.name}`}
        subTitle={`${floorPlan.description || ""}`}
        image="/apartments/norhart-apartments-life-people.png"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
      /> */}

      {floorPlan.youtubeUrl && (
        <VideoCommunitySection
          backgroundColorOne={colorPalette.heroTop}
          backgroundColorTwo={colorPalette.heroBottom}
          icon={VideoIcon}
          iconTitle="Video Tour"
          title={`Experience ${floorPlan.name}`}
          subtitle=""
          colorPalette={colorPalette}
          youtubeUrl={floorPlan.youtubeUrl}
        />
      )}

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={ApartmentIcon}
        iconTitle="Exceptional Living"
        title="Loving Life at Norhart"
        subtitle="Norhart offers more than just the old boring plain apartment life. We create living experiences where your home life and your social life unite together. Just another way Norhart is making your life better."
        imageTitleOne="Norhart Loving Life Image 1"
        imageOne="/apartments/gallery/love-life/norhart-loving-life-1.webp"
        imageTitleTwo="Norhart Loving Life Image 2"
        imageTwo="/apartments/gallery/love-life/norhart-loving-life-2.webp"
        imageTitleThree="Norhart Loving Life Image 3"
        imageThree="/apartments/gallery/love-life/norhart-loving-life-3.webp"
        imageTitleFour="Norhart Loving Life Image 4"
        imageFour="/apartments/gallery/love-life/norhart-loving-life-4.webp"
        imageTitleFive="Norhart Loving Life Image 5"
        imageFive="/apartments/gallery/love-life/norhart-loving-life-5.webp"
        imageTitleSix="Norhart Loving Life Image 6"
        imageSix="/apartments/gallery/love-life/norhart-loving-life-6.webp"
        buttonUrl="/services/"
        colorPalette={colorPalette}
        badge={false}
      />

      {floorPlan.matterportUrl && (
        <VideoCommunitySection
          backgroundColorOne={colorPalette.heroTop}
          backgroundColorTwo={colorPalette.heroBottom}
          icon={MatterportIcon}
          iconTitle="Matterport"
          title={`${floorPlan.name} Walkthrough`}
          subtitle=""
          colorPalette={colorPalette}
          youtubeUrl={floorPlan.matterportUrl}
        />
      )}

      {floorPlan.images.length > 0 && (
        <GallerySection
          backgroundColorOne="#EFEFEF"
          backgroundColorTwo="#F0F0F0"
          badge={false}
          icon={GalleryIcon}
          iconTitle="Gallery"
          title="Take A Look Around"
          subtitle="At Norhart our teams have designed and built an exceptional living experience from top to bottom just for you! Go ahead take a look around at what your next home looks like."
          colorPalette={colorPalette}
          images={floorPlan.images}
        />
      )}

      <AwardsSection
        header=""
        title="Modern Luxury Apartments"
        subtitle="At Norhart our focus is to create exceptional apartments and living experiences. We build more than just apartments or units. Our core mission is to change peoples lives!"
        colorPalette={colorPalette}
      />
    </LayoutFloorPlan>
  )
}

/** export */
/** export */
export default FloorPlanTemplate

interface FloorPlanSectionProps {
  floorPlan: any
}

const FloorPlanSection: React.FC<FloorPlanSectionProps> = (props) => {
  const floorPlan = props.floorPlan

  return (
    <>
      <section className="container-fluid p-3 bg-white">
        <a id="NavFloorPlans"></a>
        <div className="container pl-3 pr-3">
          <div className="container pb-5"></div>

          <div
            className="row mt-5"
            style={
              {
                // opacity: floorPlan.availableDate == null ? 0.25 : 1.0,
              }
            }
          >
            <div className="col-sm-6 mt-4">
              <h1 className="floor-plan-header">{floorPlan.name} </h1>
              <span
                className="badge badge-primary ms-0 mt-0 mb-2"
                style={{
                  fontSize: "0.9rem",
                  verticalAlign: "middle",
                  backgroundColor: floorPlan.availableDate == null ? "#CCCCCC" : "#ff7200",
                }}
              >
                {floorPlan.availableDate == null ? "Not Available" : "Now Available"}
              </span>
              {floorPlan.rentMin && (
                <h2 className="floor-plan-image-header ml-0 mt-2 p-0">Starting at ${floorPlan.rentMin}/mo*</h2>
              )}
              <h3 className="floor-plan-image-header ml-0 p-0">{floorPlan.squareFeetMin} Sq Ft</h3>
              <h3 className="floor-plan-sub-header mt-3 ml-0 p-0">{floorPlan.description}</h3>
              <h6 className="floor-plan-sub-header mt-3 ml-0 p-0">
                {(floorPlan.promotionalMessage ?? "").includes("Promo") == true && <StarIcon className="mb-1" />}{" "}
                {floorPlan.promotionalMessage}
              </h6>
              <div className="d-flex justify-content-left mt-3 mb-5">
                <a
                  href={getApplyLink(null, props.floorPlan?.id)}
                  className="floor-plan-button btn btn-md btn-light"
                  style={{ backgroundColor: floorPlan.availableDate == null ? "#CCCCCC" : "#259dff" }}
                >
                  Apply Now
                </a>
              </div>
            </div>
            {floorPlan.floorPlanImage && (
              <div className="col-sm-6 mb-5">
                <GatsbyImage
                  className="pb-0 pl-0 pr-0 d-none d-sm-block crop"
                  alt={floorPlan.name + " Floor Plan"}
                  image={getImage(floorPlan.floorPlanImage.localFile)}
                />

                <GatsbyImage
                  className="pb-0 pt-5 mt-0 d-block d-sm-none w-100"
                  alt={floorPlan.name + " Floor Plan"}
                  image={getImage(floorPlan.floorPlanImage.localFile)}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

const getApplyLink = (communityId?: string, floorPlanId?: string): string => {
  //TODO we'll use these when we move to Spherexx
  // if (floorPlanId) {
  //   return "https://apply.norhart.com?floor-plan=" + floorPlanId
  // }

  // if (communityId) {
  //   return "https://apply.norhart.com?community=" + communityId
  // }
  return "https://norhart.twa.rentmanager.com/applynow"
}

/** props */
interface GallerySectionProps {
  icon: any
  iconTitle: string
  title: string
  subtitle: string
  buttonUrl?: string
  colorPalette: ColorPalette
  backgroundColorOne: string
  backgroundColorTwo: string
  badge: Boolean
  images: any[]
}

/** const */
const GallerySection: React.FC<GallerySectionProps> = (props) => {
  const Icon = props.icon

  return (
    <section
      className="container-fluid text-center pt-5 pb-5"
      style={{
        background: `linear-gradient(${props.backgroundColorOne} 30%, ${props.backgroundColorTwo} 70%)`,
        color: `${props.colorPalette.sectionText}`,
      }}
    >
      <a id="NavGallery"></a>
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <Icon />
        </div>
        <div className="hidden-sm hidden-md hidden-lg hidden-xl">
          {props.badge == true ? (
            <span
              className="badge badge-secondary ms-0 mt-2 mb-4"
              style={{
                fontSize: "0.9rem",
                verticalAlign: "middle",
                backgroundColor: `${props.colorPalette.buttonNew}`,
              }}
            >
              {props.iconTitle}
            </span>
          ) : (
            <strong>{props.iconTitle}</strong>
          )}
        </div>

        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mb-3 mt-3"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
        />
        <h3 className="contentHeroTitle" style={{ color: `${props.colorPalette.sectionText}` }}>
          {props.title}
        </h3>
        <h4
          className="contentHeroSubTitle mt-0 mb-5"
          style={{ color: `${props.colorPalette.sectionText}`, opacity: "0.6" }}
        >
          {props.subtitle}
        </h4>

        {props.buttonUrl && (
          <div className="d-flex justify-content-center mb-5">
            <a
              className="btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow"
              href={props.buttonUrl}
              target="_blank"
              style={{ backgroundColor: "#ffffff", border: "0px", color: "#333333" }}
            >
              Learn More
            </a>
          </div>
        )}

        <Gallery itemsPerRow={2} images={props.images} />
      </div>
    </section>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    floorPlan(id: { eq: $id }) {
      amenities {
        name
        amenityGroup
      }
      availableDate
      bathrooms
      bedrooms
      dens
      description
      id
      matterportUrl
      minimumDeposit
      name
      rentMax
      rentMin
      squareFeetMax
      squareFeetMin
      url
      urlPath
      youtubeUrl
      promotionalMessage
      promotionalStartDate
      promotionalEndDate
      featuredImage {
        url
        caption
        altText
        tag
        localFile {
          childImageSharp {
            gatsbyImageData(width: 1920, formats: WEBP)
          }
        }
      }
      floorPlanImage {
        url
        caption
        altText
        tag
        localFile {
          childImageSharp {
            gatsbyImageData(breakpoints: [200, 300, 400, 500, 600, 800, 1000, 1200], formats: WEBP)
          }
        }
      }
      community {
        id
        name
        region {
          name
        }
        primaryProperty {
          addressLine1
          city
          dateBuilt
          latitude
          longitude
          state
          postalCode
        }
        featuredImage {
          url
          caption
          altText
          tag
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1200, formats: WEBP)
            }
          }
        }
        url
        urlPath
        urlSlug
        rentRanges {
          bedrooms
          rentMin
          rentMax
        }
        shortDescription
        allowOnlineApplications
      }
      images {
        url
        caption
        displayOrder
        altText
        tag
        localFile {
          childImageSharp {
            gatsbyImageData(breakpoints: [200, 300, 400, 500, 600, 800, 1000, 1200], formats: WEBP)
          }
        }
      }
      terms {
        month
        fee
      }
    }
    allCommunityLeadSource {
      nodes {
        communityId
        communityName
        leadSourceAbbreviation
        leadSourceName
        emailAddress
        phoneNumber
      }
    }
  }
`
